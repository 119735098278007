import React, { useState } from 'react';
import Layout from '../components/Layout';

import { Link } from 'gatsby';
import { SubscriptionForm } from '../components/SubscriptionForm';
import SEO from '../components/seo';
import { Typography, Paper } from '@material-ui/core';
import { graphql } from 'gatsby';
import { red } from '@material-ui/core/colors';
import pathToImageOfAnAppp from './timers-demo.png';

const Header = ({ children }) => (
  <Typography
    variant={'overline'}
    color={'textSecondary'}
    style={{ textTransform: 'uppercase' }}
  >
    {children}
  </Typography>
);

export default (props) => {
  const {
    data: {
      site: {
        siteMetadata: { title },
      },
    },
    location,
  } = props;
  return (
    <Layout location={location} title={title}>
      <SEO
        title={'FOCUTRON'}
        keywords={[`google assisstant`, `timers`, `menu bar`]}
      />
      <Typography variant={'h3'} color={'secondary'}>
        Mastering Notion
      </Typography>
      <Typography variant={'headline'} color={'secondary'}>
        Online course to make the shit out of Notion
      </Typography>
      Hi. I've been using Notion for the last 3 years and after many years of
      doing porn with all-that-tools I've managed to make actual use out of
      Notion. I've created my personal vision and tool
      <br />
      <br />
      <Header>Mental Attitude</Header>
      <ul>
        <li>asdas</li>
      </ul>
      <Header>Expect</Header>
      <ul>
        <li>Using Notion to manage vision, goals and everyday tasks</li>
        <li>Using Notion to learn, better</li>
        <li>Using Notion for self-reflection</li>
      </ul>
      <br />
      <br />
      <Header>OVERVIEW</Header>
      <Typography paragraph variant={'headline'}>
        I write this app for myself to see timers I've set up by voice with by
        Google Assistant in Mac OS menu bar.
      </Typography>
      <br />
      <br />
      <Header>SUBSCRIBE</Header>
      <Typography paragraph variant={'headline'}>
        The app WILL update so subsribe to stay in touch.
      </Typography>
      <SubscriptionForm />
      <br />
      <br />
      <Header>SOURCE CODE</Header>
      <Typography paragraph variant={'headline'}>
        You have to build an app for your target platform.
      </Typography>
      <a
        style={{ color: red[500] }}
        href={'https://github.com/rohovdmytro/focutron'}
      >
        https://github.com/rohovdmytro/focutron
      </a>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
      }
    }
  }
`;
